<template>
  <v-container>
    <v-card outlined class="pa-1 mb-1 rounded-xl mx-auto" color="transparent">
      <v-layout wrap>
        <v-select dense outlined  rounded class="ma-1" style="height: 40px; max-width: 300px;" 
          id="farm" 
          ref="farm" 
          label="농장/사업장✽" 
          v-model="farm"
          :items="farmItems"
          :menu-props="{ top: false, offsetY: true }"
          no-data-text="자료(권한)이 없습니다."
          item-text="name"
          return-object
          @change="changeFarm()"
          @focus="comboFarms()"
          clearable
          >
        </v-select>
        <v-btn class="elevation-5 ma-1 rounded-pill normal-btn" style="height: 40px; width: 110px"
          id="refreshList" 
          :loading="loading" 
          ref="refreshList" 
          @click="refreshList()">
          <img src="@/assets/icon_refresh.svg" alt="새로고침" />
          조회
        </v-btn>
      </v-layout>
    </v-card>

    <div class="mt-3">
      <div v-for="(fogline,index) in fog" :key="index">
        <div class="model_content">
          <div class="fog_title">
            <div class="model_title">{{ fogline.name }} {{ fogline.Slave_id }}</div>

            <div>
              <v-btn block
                class="set_btn rounded-circle"
                style="width:35px !important; height:35px !important;"
                v-if="monitoringIndex !== index"
                @click="setting(index)">
                <img :src="set_icon" class="setting_icon" />
              </v-btn>
              <v-btn block
                class="set_btn"
                style="width:50px !important; border-radius: 15px; font-size:15px;"
                v-else
                @click="save(index)">
                저장
              </v-btn>
            </div>
          </div>

          <div v-if="monitoringIndex !== index">
            <div class="fogline_content">

              <div class="fogline_info" style="width:95px;">
                <div class="fogline_title">작동 잔여 시간</div>
                <div class="active_info" style="background-color:#EDEDED; border:1px solid transparent !important;">
                  <div class="fogline_time">{{ fogline.counter }}</div>
                  <div style="font-size:15px;">초</div>
                </div>
              </div>

              <div class="fogline_info">
                <div class="fogline_title">분사1</div>
                <div class="active_info" :class="fogline.operation == '1' ? 'active':'noactive' ">
                  <div class="fogline_time">{{ fogline.mist1 }}</div>
                  <div style="font-size:15px;">십초</div>
                </div>
                <div class="fogline_status_on" v-if="fogline.operation == '1' "></div>
                <div class="fogline_status_off" v-else></div>
              </div>

              <div class="fogline_info">
                <div class="fogline_title">분사2</div>
                <div class="active_info"  :class="fogline.operation == '2' ? 'active':'noactive' ">
                  <div class="fogline_time">{{ fogline.mist2 }}</div>
                  <div style="font-size:15px;">분</div>
                </div>
                <div class="fogline_status_on" v-if="fogline.operation == '2' "></div>
                <div class="fogline_status_off" v-else></div>
              </div>

              <div class="fogline_info">
                <div class="fogline_title">에어</div>
                <div class="active_info"  :class="fogline.operation == '3' ? 'active':'noactive' ">
                  <div class="fogline_time">{{ fogline.air }}</div>
                  <div style="font-size:15px;">초</div>
                </div>
                <div class="fogline_status_on" v-if="fogline.operation == '3' "></div>
                <div class="fogline_status_off" v-else></div>
              </div>

              <div class="fogline_info">
                <div class="fogline_title">쉬는시간</div>
                <div class="active_info">
                  <div class="fogline_time">{{ fogline.rest }}</div>
                  <div style="font-size:15px;">분</div>
                </div>
                <div class="fogline_status_off"></div>
              </div>

              <div class="fogline_info">
                <div class="fogline_title">온도설정</div>
                <div class="active_info">
                  <div class="fogline_time">{{ fogline.set_temp }}</div>
                  <div style="font-size:15px;">℃</div>
                </div>
                <div class="fogline_status_off"></div>
              </div>

              <div>
                <div class="status_info">
                  <div style="width:120px; text-align:center;">
                    <div class="fogline_title">스위치</div>
                    <div style="font-size:11px;">(자동/정지/수동)</div>
                  </div>
                  <div class="status_mode">
                    <div v-if="fogline.switch_mode == '1' ">자동</div>
                    <div v-else-if="fogline.switch_mode == '2' ">정지</div>
                    <div v-else>수동</div>
                  </div>
                </div>

                <div class="status_info">
                  <div style="width:120px; text-align:center;">
                    <div class="fogline_title">현재모드</div>
                    <div style="font-size:11px;">(로컬자동/원격자동)</div>
                  </div>
                  <div class="status_mode">
                    <div v-if="fogline.control_mode == '1' "> 로컬 </div>
                    <div v-else-if="fogline.control_mode == '2' "> 원격 </div>
                    <div v-else> - </div>
                  </div>
                </div>

                <div class="status_info">
                  <div style="width:120px; text-align:center;">
                    <div class="fogline_title">온도 센서값</div>
                  </div>
                  <div class="status_mode">{{fogline.now_temp}} ℃</div>
                </div>
              </div>
            </div>
          </div>


          <div v-else>
            <div class="fogline_content">
              <div class="fogline_info">
                <div class="fogline_title">분사1</div>
                <input 
                  type="number"
                  ref="fogline1"
                  class="control_value"
                  v-model.number="fogline1"
                >
                <div class="control_unit">십초</div>
              </div>

              <div class="fogline_info">
                <div class="fogline_title">분사2</div>
                <input 
                  type="number"
                  ref="fogline2"
                  class="control_value"
                  v-model.number="fogline2"
                >
                <div class="control_unit">분</div>
              </div>

              <div class="fogline_info">
                <div class="fogline_title">에어</div>
                <input 
                  type="number"
                  ref="air"
                  class="control_value"
                  v-model.number="air"
                >
                <div class="control_unit">초</div>
              </div>

              <div class="fogline_info">
                <div class="fogline_title">쉬는시간</div>
                <input 
                  type="number"
                  ref="rest"
                  class="control_value"
                  v-model.number="rest"
                >
                <div class="control_unit">분</div>
              </div>

              <div class="fogline_info">
                <div class="fogline_title">온도설정</div>
                <input
                  type="number" 
                  ref="temp"
                  class="control_value"
                  v-model.number="temp"
                >
                <div class="control_unit">℃</div>
              </div>

              <div>
                <div class="status_info">
                  <div style="width:120px; text-align:center;">
                    <div class="fogline_title">스위치</div>
                    <div style="font-size:11px;">(자동/정지/수동)</div>
                  </div>
                  <div class="status_mode">
                    <div v-if="fogline.switch_mode == '1' ">자동</div>
                    <div v-else-if="fogline.switch_mode == '2' ">정지</div>
                    <div v-else>수동</div>
                  </div>
                </div>

                <div class="status_info">
                  <div style="width:120px; text-align:center;">
                    <div class="fogline_title">현재모드</div>
                    <div style="font-size:11px;">(전자동/수동/로컬)</div>
                  </div>
                  <div class="status_mode">
                    <div v-if="fogline.control_mode == '1' ">전자동</div>
                    <div v-else-if="fogline.control_mode == '2' ">수동</div>
                    <div v-else>로컬</div>
                  </div>
                </div>

                <div class="status_info">
                  <div style="width:120px; text-align:center;">
                    <div class="fogline_title">온도 센서값</div>
                  </div>
                  <div class="status_mode">{{fogline.now_temp}} ℃</div>
                </div>
              </div>
            </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </v-container>
</template>

<script>
import Common from "@/utils/custom/common.js";
import Apis from '@/api/apis';
import dateUtil from "@/utils/custom/dateProperty.js";
import data from "@/assets/json/jy.json"

export default {
  name: "JYDeodorizer",
  data(){
    return{
      set_icon: require("@/assets/monitoring/setting.svg"),

      fog:data.data,

      monitoringIndex: null,

      timer:null,

      fogline1:'',
      fogline2:'',
      air:'',
      rest:'',
      temp:'',

      farm: {},
      farmItems: [],

      loading: false,

    }
  },
  created(){
  },
  mounted(){
    // this.startMonitoring();
  },
  beforeDestroy() {
    this.stopMonitoring();
  },

  methods:{
    startMonitoring() {
      this.timer = setTimeout(() => {
        console.log('~~새로고침~~');
        this.startMonitoring(); 
      }, 1000);
    },
    stopMonitoring() {
      clearTimeout(this.timer);
    },
    setting(index){
      if (this.monitoringIndex === index) {
        this.monitoringIndex = null; 
      } else {
        this.monitoringIndex = index; 
        this.stopMonitoring();
      }
    },
    checkData(index){
      if(!Common.isNumeric(this.fogline1) || this.fogline1 < 0 || this.fogline1 > 100 ){
        this.$refs.fogline1[index].focus();
          return "정확한 값을 입력하세요(0~99)";
      }

      if(!Common.isNumeric(this.fogline2) || this.fogline2 < 0 || this.fogline2 > 10 ){
        this.$refs.fogline2[index].focus();
          return "정확한 값을 입력하세요(0~10)";
      }

      if(!Common.isNumeric(this.air) || this.air < 0 || this.air > 100 ){
        this.$refs.air[index].focus();
          return "정확한 값을 입력하세요(0~99)";
      }

      if(!Common.isNumeric(this.rest) || this.rest < 0 || this.rest > 100 ){
        this.$refs.rest[index].focus();
          return "정확한 값을 입력하세요(0~99)";
      }

    },
    save(index){  
      let msgCheckdata = this.checkData(index); 
      if (msgCheckdata){
        this.$store.commit("resMessage",msgCheckdata);
        // alert(msgCheckdata);
        return;
      }
      alert('컨트롤러 설정 값을 변경하시겠습니까?');
    },
    async comboFarms() {
      await Apis.comboFarms({
        company_cd: this.company && this.company.code || "",
        stand_date: dateUtil.format(new Date(), "yyyy-MM-dd"), 
        },(res) => {  
          if (res.result) {
            this.farmItems = [];
            for (let i in res.data) {
            this.farmItems.push({
              company_cd: res.data[i].company_cd, 
              company_name: res.data[i].company_name, 
              code: res.data[i].farm_cd,
              name: res.data[i].farm_name,
              });
            }  
          } else {
            alert(res.message);
          }
        }).catch( (err) => {  // API 오류 처리
            console.log("comboFarms API 호출 오류",err)
        }
      ) 
    },
  },
}
</script>

<style>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
@import url('https://fonts.cdnfonts.com/css/ds-digital');
@font-face {
  font-family: 'GmarketSansMedium';
  src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2001@1.1/GmarketSansMedium.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  transform : rotate(0.04deg);
}

*{
  letter-spacing: -1.25px;
}
.normal-btn{
  align-content: center !important;
  color:white !important;
  font-weight: bold !important;
  background-color: #3a4f3f !important;
}
.set_btn{
  align-content: center !important;
  color:white !important;
  background-color: #3a4f3f !important;
}
.setting_icon{
  width:18px;
}
.model_content{
  padding:15px;
  margin:10px;
  /* border:1px solid #E2E2E2; */
  background: #f9f9f9;
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.1);
  border-radius: 30px;
}
.model_title, .fogline_title, .fogline_time{
  font-weight:600;
  font-size:18px;
}
.fogline_time{
  font-size:40px;
  font-weight:bold;
  font-family: 'DS-Digital';
}
.fogline_content , .fog_title ,.status_info{
  display: flex;
  text-align:center;
  justify-content: center;
  align-items: center;
  align-content: center; 
}
.fog_title{
  justify-content: space-between;
}
.fogline_info{
  width:10%;
  height:170px;
  margin:5px 10px 5px 10px;
}
.active_info{
  padding:10px;
  border:1px solid gray;
  border-radius: 20px;
  background:white;
}
.noactive{
  background:white;
}
.active{
  border:1px solid transparent !important;
  background-color: #FFF8DC;
}
.status_info{
  margin-top:5px;
}
.status_mode{
  text-align:center;
  width:100px;
  background: #e3e3e3;
  padding:5px;
  border-radius: 10px;
}
.fogline_status_on{
  background-color:#88191a;
  width:30px;
  height:30px;
  border-radius: 50%;
  animation: blink-effect 1s step-end infinite;
  text-align: center;
  margin: 0 auto;
  margin-top:10px;
}
.fogline_status_off{
  background-color:#e5e5e5;
  width:30px;
  height:30px;
  border-radius: 50%;
  text-align: center;
  margin: 0 auto;
  margin-top:10px;
}
@keyframes blink-effect {
  50% {
    opacity: 0;
  }
}

.control_value{
  width:100%;
  height:120px;
  text-align:center;
  background-color: #FFF8DC;
  border-width: 0 0 1px;
  font-size:40px;
  font-weight:bold;
  font-family: 'DS-Digital';
  border-radius: 20px;
  transition: box-shadow 0.3s ease-in-out;
}
.control_value:focus{
  outline: none;
  border-color: #D5DAB9;
  box-shadow: 0 0 8px rgba(102, 175, 233, 0.6);
}
.control_unit{
  margin-top:-40px;
  margin-left:5px;
}

</style>